export const Config = {
  CUSTOMER_SERVICE_API:
    process.env.REACT_APP_CUSTOMER_SERVICE_API || "/api/customer/api/v1/",
  CUSTOMER_MASTER_API:
    process.env.REACT_APP_MASTER_SERVICE_API || "/api/master/api/v1/",
  CUSTOMER_BOOKING_SERVICE_API:
    process.env.REACT_APP_BOOKING_SERVICE_API || "/api/booking/api/v1/",
  NOTIFICATION_SERVICE_API:
    process.env.REACT_APP_NOTIFICATION_SERVICE_API ||
    "/api/notification/api/v1/",
  PAYMENT_SERVICE_API:
    process.env.REACT_APP_PAYMENT_SERVICE_API ||
    "/api/payment/api/processpayment/",
  IMAGE_URL: "/api/customer/",
  CAPTCHA_KEY:
    process.env.REACT_APP_CAPTCHA_KEY ||
    "6LcI_ksmAAAAADaoxE88Rb3T8IOdYoncBMbCmwMg",
  // HOST_URL: process.env.REACT_APP_HOST_URL || "https://ui.kmb-dev.ults.build",
  HOST_URL: process.env.REACT_APP_HOST_URL || "https://ui.kmb-dev.ults.build",
  RAZORPAY_KEY_ID: "rzp_live_qyvNRG8coxGpxK",
};
